import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import styled from 'styled-components'
import Slider from 'rc-slider'
import IMask from 'imask/esm/imask'
import 'imask/esm/masked/number'
import 'imask/esm/masked/dynamic'

import { nanoid } from 'nanoid'

import 'rc-slider/assets/index.css'

import Label from './Label'

interface Props {
    label: string
    name: string
    min: number
    max: number
    step?: number
    postfix?: string
    value?: number
    sliderValue?: number
    disabled?: boolean
    inputMin?: number
    inputMax?: number
    onSliderChange?: (e: number) => void
    onChange: (e: number) => void
}

const Control = styled.input`
    color: #1a0e0e;
    border: 1px solid #e6ddcf;
    background-color: #fcfaf7;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    padding-top: 24px;
    padding-bottom: 16px;
    font-family: Futura;
    width: 100%;

    &:focus {
        outline: none;
    }
`

const Wrap = styled.div`
    position: relative;
    display: flex;
    min-width: 1px;

    .rc-slider {
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 7px;
    }

    .rc-slider-track {
        background-color: #cc5f42;
        opacity: 0.7;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 7px;
    }

    .rc-slider-rail {
        background-color: #e6ddcf;
        border-radius: 12px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 7px;
    }

    .rc-slider-handle {
        background-color: #cc5f42;
        border-color: #cc5f42;
        opacity: 1;

        @media (max-width: 768px) {
            width: 18px;
            height: 18px;
            margin-top: -6px;
        }
    }

    .rc-slider-handle-dragging {
        box-shadow: none !important;
        border-color: #cc5f42 !important;
    }
`

const SliderInput = ({
    label,
    name,
    min,
    max,
    value,
    step,
    sliderValue,
    postfix,
    disabled,
    inputMin,
    inputMax,
    onSliderChange,
    onChange,
}: Props) => {
    const id = useRef<string>(nanoid())
    const input = useRef<HTMLInputElement>(null)
    const mask = useRef<any>()
    const [focus, setFocus] = useState<boolean>(false)

    useEffect(() => {
        if (input.current) {
            mask.current = IMask(input.current, {
                mask: [
                    { mask: '' },
                    {
                        mask: `num ${postfix}`,
                        lazy: false,
                        blocks: {
                            num: {
                                mask: Number,
                                scale: 0,
                                signed: false,
                                thousandsSeparator: ' ',
                                padFractionalZeros: false,
                                normalizeZeros: true,
                                radix: ',',
                                mapToRadix: ['.'],
                            },
                        },
                    },
                ],
            })
        }
    }, [])

    useEffect(() => {
        if (value) {
            mask.current.unmaskedValue = value.toString()

            if (value.toString().length > 0) {
                setFocus(true)
                return
            }

            setFocus(false)
        }
    }, [value])

    const handleChange = (val: string | number) => {
        const minVal = inputMin ? inputMin : min
        const maxVal = inputMax ? inputMax : max
        let valueInt = val

        if (typeof valueInt === 'number') {
            if (valueInt < minVal) valueInt = minVal
            if (valueInt > maxVal) valueInt = maxVal
        }

        if (typeof valueInt === 'string') {
            valueInt = parseInt(valueInt.replace(/\s/g, ''), 10)

            if (valueInt < minVal) valueInt = minVal
            if (valueInt > maxVal) valueInt = maxVal
        }

                
        if (val.toString().length < 1) {
            setFocus(false)
        }

        mask.current.unmaskedValue = valueInt.toString()

        onChange(mask.current.unmaskedValue)
    }

    const handleKeyUp = ({ key, target }: KeyboardEvent) => {
        const el = target as HTMLInputElement

        if (key === 'Enter') {
            handleChange(el.value)
        }
    }

    return (
        <Wrap>
            <Label focused={focus} htmlFor={id.current}>
                {label}
            </Label>
            <Control
                ref={input}
                type="text"
                id={id.current}
                value={mask.current && mask.current.value}
                name={name}
                onFocus={() => setFocus(true)}
                onBlur={(e: Event) =>
                    handleChange((e.target as HTMLInputElement).value)
                }
                onKeyup={(e: KeyboardEvent) => handleKeyUp(e)}
                disabled={disabled}
            />
            <Slider
                value={sliderValue ? sliderValue : value}
                onChange={(e: number) =>
                    onSliderChange ? onSliderChange(e) : handleChange(e)
                }
                min={min}
                max={max}
                step={step || 1}
                disabled={disabled}
            />
        </Wrap>
    )
}

export default SliderInput
