import { h } from 'preact'
import styled from 'styled-components'
import { nanoid } from 'nanoid'
import { useRef, useState } from 'preact/hooks'

import Label from './Label'

interface SelectProps {
    label: string
    name: string
    options: string[]
    value: string
    onChange: (e: string) => void
}

const Wrap = styled.div`
    display: flex;
    position: relative;
    min-width: 1px;
    box-sizing: border-box;
`

const Control = styled.select`
    color: #1a0e0e;
    border: 1px solid #e6ddcf;
    background-color: #fcfaf7;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    padding-right: 40px;
    font-family: Futura;
    width: 100%;
    display: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none' transform='rotate(-90)'%3E%3Ccircle r='32' transform='matrix(-1 0 0 1 32 32)' fill='%23cc5f42'%3E%3C/circle%3E%3Cg clip-path='url(%23clip0_57_50)'%3E%3Cpath d='M26 32H40' stroke='%23fff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M30 27L25 32L30 37' stroke='%23fff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_57_50'%3E%3Crect width='19' height='14' fill='white' transform='matrix(-1 0 0 1 42 25)'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E ");
    background-position: calc(100% - 20px) center;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    appearance: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (hover: none) {
        display: block;
    }
`

const SelectValue = styled.div`
    color: ${(props) => (props.value ? '#1a0e0e' : '#ccba9d')};
    border: 1px solid #e6ddcf;
    background-color: #fcfaf7;
    border-radius: 12px;
    padding: 20px;
    padding-top: 24px;
    padding-bottom: 16px;
    padding-right: 40px;
    font-family: Futura;
    width: 100%;
    cursor: pointer;
    display: flex;
    min-width: 1px;
    box-sizing: border-box;

    span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @media (hover: none) {
        display: none;
    }

    &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none' transform='rotate(-90)'%3E%3Ccircle r='32' transform='matrix(-1 0 0 1 32 32)' fill='%23cc5f42'%3E%3C/circle%3E%3Cg clip-path='url(%23clip0_57_50)'%3E%3Cpath d='M26 32H40' stroke='%23fff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M30 27L25 32L30 37' stroke='%23fff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_57_50'%3E%3Crect width='19' height='14' fill='white' transform='matrix(-1 0 0 1 42 25)'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E ");
        transform: ${(props) => (props.active ? 'rotate(180deg)' : 'none')};
        position: absolute;
        left: calc(100% - 60px);
        top: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat
        background-position: center;
        margin: auto;
    }
`
const SelectDropdown = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    background-color: #fcfaf7;
    border: 1px solid #e6ddcf;
    border-radius: 12px;
    padding: 20px;
    left: 0;
    display: grid;
    gap: 10px;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
`

const SelectDropdownItem = styled.button`
    border: none;
    background: transparent;
    text-align: left;
    padding: 0;
    cursor: pointer;
    line-height: 140%;
    font-family: Futura;
    font-size: 16px;

    span {
        font-weight: 700;
        color: #cc5f42;
    }
`

const Select = ({ label, name, options, value, onChange }: SelectProps) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const wrapEl = useRef<HTMLElement>(null)
    const id = useRef<string>(nanoid())

    const handleOptionClick = (value: string) => {
        onChange(value)
        setShowDropdown(false)
    }

    document.addEventListener('click', (e) => {
        const el = e.target as HTMLElement

        if (!wrapEl.current?.contains(el)) {
            setShowDropdown(false)
        }
    })

    return (
        <Wrap ref={wrapEl}>
            <Label focused={value} htmlFor={id.current}>
                {label}
            </Label>
            <Control
                id={id.current}
                name={name}
                value={value}
                onChange={(e: Event) =>
                    handleOptionClick((e.target as HTMLInputElement).value)
                }
            >
                {options &&
                    options.map((option) => (
                        <option key={option}>{option}</option>
                    ))}
            </Control>
            <SelectValue
                onClick={() => setShowDropdown(!showDropdown)}
                value={value}
                active={showDropdown}
            >
                <span>{value || label}</span>
            </SelectValue>
            {showDropdown && (
                <SelectDropdown>
                    {options.map((option) => (
                        <SelectDropdownItem
                            key={option}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.split('от')[0]} от{' '}
                            <span>{option.split('от')[1]}</span>
                        </SelectDropdownItem>
                    ))}
                </SelectDropdown>
            )}
        </Wrap>
    )
}

export default Select
