import { h } from 'preact'
import { useState } from 'preact/hooks'
import styled from 'styled-components'

import Select from './form/Select'
import SliderInput from './form/SliderInput'
import Button from './Button'
import Modal from './Modal'
import ContactForm from './ContactForm'

const typeOptions = [
    'Семейная от 5.3%',
    'Господдержка от 7.3%',
    'Базовая программа от 10.9%',
]

const CalcWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    min-width: 1px;


    @media (max-width: 768px) {
        gap: 10px;
    }
`


const AppWrap = styled.div`
    font-family: Futura;
    max-width: calc(1160px - 32px);
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: stretch;
    box-sizing: border-box;

    *, *::before, &::after {
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const Results = styled.ul`
    list-style: none;
    margin: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    border: 1px dashed #e6ddcf;
    border-radius: 8px;
    padding: 24px;
    gap: 16px;
    box-sizing: border-box;
    min-width: 1px;
    font-size: 20px;

    @media (max-width: 600px) {
        gap: 16px;
        font-size: 16px;
    }
`

const ResultItem = styled.li`
    text-align: left;

    &:last-of-type {
        margin-top: auto;
    }
`

const App = () => {
    const [type, setType] = useState<string>('Господдержка от 7.3%')
    const [totalPrice, setTotalPrice] = useState<number>(3000000)
    const [period, setPeriod] = useState<number>(20)
    const [firstPayPercentage, setFirstPayPercentage] = useState<number>(20)

    const [showModal, setShowModal] = useState<boolean>(false)

    const firstPaySumm = () => {
        if (!firstPayPercentage || !totalPrice) return

        const percentage = firstPayPercentage / 100
        return ~~(totalPrice * percentage)
    }

    const handleFirstPaySummChange = (summ: number) => {
        if (!totalPrice) return

        const percentage = (summ / totalPrice) * 100
        setFirstPayPercentage(percentage)
    }

    const creditSumm = () => {
        const firstPay = firstPaySumm()
        if (!totalPrice || !firstPay) return 0

        return totalPrice - firstPay
    }

    const result = () => {
        const firstPay = firstPaySumm()
        if (!type || !totalPrice || !period || !firstPayPercentage || !firstPay)
            return 0

        // Monthly rate
        const rateMonthly = parseFloat(type.split('от')[1]) / 100 / 12
        // Total period in moths
        const periodInMonths = period * 12
        // Total credit summ
        const creditSumm = totalPrice - firstPay

        return Math.ceil(
            (creditSumm *
                (rateMonthly * Math.pow(1 + rateMonthly, periodInMonths))) /
                (Math.pow(1 + rateMonthly, periodInMonths) - 1)
        )
    }

    const handleModalOpen = (e: MouseEvent) => {
        e.stopPropagation()
        setShowModal(true)
    }

    return (
        <AppWrap id="app">
            <CalcWrap>
                <Select
                    name="type"
                    label="Тип"
                    value={type}
                    options={typeOptions}
                    onChange={(e) => setType(e)}
                />
                <SliderInput
                    name="price"
                    label="Стоимость жилья"
                    postfix="₽"
                    min={1000000}
                    max={50000000}
                    step={100000}
                    value={totalPrice}
                    onChange={(e) => setTotalPrice(e)}
                />
                <SliderInput
                    name="first_pay"
                    label="Первоначальный взнос"
                    postfix="₽"
                    min={10}
                    max={70}
                    inputMin={totalPrice && ~~(totalPrice * 0.1)}
                    inputMax={totalPrice && ~~(totalPrice * 0.7)}
                    value={firstPaySumm()}
                    sliderValue={firstPayPercentage}
                    onChange={(e) => handleFirstPaySummChange(e)}
                    onSliderChange={(e) => setFirstPayPercentage(e)}
                />
                <SliderInput
                    name="period"
                    label="Срок кредита"
                    postfix="лет"
                    min={7}
                    max={30}
                    value={period}
                    onChange={(e) => setPeriod(e)}
                />
            </CalcWrap>

            <Results>
                <ResultItem>
                    Сумма кредита: <b>{creditSumm().toLocaleString()} ₽</b>
                </ResultItem>
                <ResultItem>
                    Ежемесячный платеж: <b>{result().toLocaleString()} ₽</b>
                </ResultItem>
                <ResultItem>
                    Процентная ставка: <b>{type.split('от')[1]}</b>
                </ResultItem>
                <ResultItem>
                    Срок кредита: <b>{period ? `${period} лет` : '-'}</b>
                </ResultItem>
                <ResultItem>
                    <Button onClick={handleModalOpen}>Подобрать</Button>
                </ResultItem>
            </Results>

            <Modal
                title="Подберем лучшие условия  по ипотеке"
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                <ContactForm
                    totalSumm={totalPrice || 0}
                    result={result()}
                    percentage={type.split('от')[1]}
                    period={period || 0}
                    firstPay={firstPaySumm() || 0}
                />
            </Modal>
        </AppWrap>
    )
}

export default App
