import styled from 'styled-components'

const Label = styled.label`
    position: absolute;
    color: #ccba9d;
    left: 20px;
    height: fit-content;
    top: -5px;
    bottom: 0;
    margin: auto;
    pointer-events: none;
    transition: 250ms;

    ${props => props.focused && `
        top: -32px;
        font-size: 12px;
        transition: 250ms;
    `}
`

export default Label