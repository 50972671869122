import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import styled from 'styled-components'
import IMask from 'imask/esm/imask'
import 'imask/esm/masked/pattern'
import { nanoid } from 'nanoid'
import 'rc-slider/assets/index.css'

import Label from './Label'

interface Props {
    label: string
    name: string
    value?: string
    disabled?: boolean
    type?: string
    required?: boolean
    onChange: (e: string) => void
}

const Control = styled.input`
    color: #1a0e0e;
    border: 1px solid #e6ddcf;
    background-color: #fcfaf7;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    padding-top: 24px;
    padding-bottom: 16px;
    font-family: Futura;
    width: 100%;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #ccba9d;
    }
`

const Wrap = styled.div`
    position: relative;
    display: flex;
    min-width: 1px;
`

const Input = ({
    label,
    name,
    value,
    disabled,
    type,
    required,
    onChange,
}: Props) => {
    const id = useRef<string>(nanoid())
    const input = useRef<HTMLInputElement>(null)
    const mask = useRef<any>()
    const [focus, setFocus] = useState<boolean>(false)

    useEffect(() => {
        if (input.current && type === 'tel') {
            mask.current = IMask(input.current, {
                mask: '+{7} (000) 000-00-00',
            })
        }
    }, [])

    useEffect(() => {
        if (value) {
            mask.current.unmaskedValue = value.toString()

            if (value.toString().length > 0) {
                setFocus(true)
                return
            }
        }
    }, [value])

    const handleChange = (val: string | number) => {
        if (typeof val === 'number') mask.current.unmaskedValue = val.toString()
        if (typeof val === 'string') mask.current.value = val

        onChange(mask.current.unmaskedValue)
    }

    return (
        <Wrap>
            <Label focused={focus} htmlFor={id.current}>
                {label}
            </Label>
            <Control
                ref={input}
                type={type || 'text'}
                id={id.current}
                value={mask.current && mask.current.value}
                name={name}
                required={required}
                onFocus={() => setFocus(true)}
                onInput={(e: Event) =>
                    handleChange((e.target as HTMLInputElement).value)
                }
                disabled={disabled}
            />
        </Wrap>
    )
}

export default Input
