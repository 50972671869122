import { Fragment, h } from 'preact'
import { useState } from 'preact/hooks'
import styled from 'styled-components'
import { ofetch } from 'ofetch'

import Button from './Button'
import Input from './form/Input'

declare global {
    interface Window {
        ym: any
    }
}

interface Props {
    result: number
    percentage: string
    period: number
    totalSumm: number
    firstPay: number
}

const Form = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
`

const OrderList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @media (max-width: 500px) {
        grid-template-columns: 1fr
    }
`

const OrderItem = styled.li`
    span {
        font-weight: 700;
    }
`

const SuccessMessage = styled.div`
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items:center;
    height: 210px;
`

const ContactForm = ({ result, percentage, period, totalSumm, firstPay }: Props) => {
    const [tel, setTel] = useState<string>('')
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    const handleSubmit = async () => {
        const data = new FormData()
        window.ym(92535706,'reachGoal','calc_lead')

        data.append('Ежемесячный платеж:', `${result.toLocaleString()} ₽`)
        data.append('Процентная ставка:', percentage)
        data.append('Сумма:', `${(totalSumm - firstPay).toLocaleString()} ₽`)
        data.append('Срок:', `${period} лет`)
        data.append('Телефон:', `${tel}`)

        const resp = await ofetch('/process.php', {
            method: 'POST',
            body: data
        })

        console.log(resp)

        if (!resp.success) {
            alert('Что то пошло не так. Попробуйте отправить заявку еще раз')
            return
        }

        setShowSuccess(true)
    }

    return (
        <Fragment>
            {!showSuccess ? (
                <Form>

                    <OrderList>
                        <OrderItem>
                            Ежемесячный платеж <span>{result.toLocaleString()} ₽</span>
                        </OrderItem>
                        <OrderItem>
                            Процентная ставка <span>{percentage}</span>
                        </OrderItem>
                        <OrderItem>
                            Сумма кредита{' '}
                            <span>{(totalSumm - firstPay).toLocaleString()} ₽</span>
                        </OrderItem>
                        <OrderItem>
                            Срок <span>{period} лет</span>
                        </OrderItem>
                    </OrderList>
                    <Input
                        label="Номер телефона"
                        name="tel"
                        type="tel"
                        value={tel}
                        onChange={(e) => setTel(e)}
                    />
                    <Button disabled={tel.length < 11} type="button" onClick={handleSubmit}>Подобрать</Button>

                </Form>
            ) : (
                <SuccessMessage>
                    Спасибо! Ваша заявка отправлена.
                </SuccessMessage>
            )}
        </Fragment>
    )
}

export default ContactForm
