import { h, VNode, Fragment } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import styled from 'styled-components'

interface Props {
    title?: string
    children?: VNode<string>[] | VNode<string>
    show: boolean
    onHide: () => void
}

const ModalWrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0 0 0 / 60%);
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalContent = styled.div`
    max-width: 550px;
    width: calc(100% - 64px);
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;

    @media (max-width: 600px) {
        width: 100%;   
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 0;
    }
`

const ModalTitle = styled.div`
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: 600px) {
        text-align: left;
        max-width: 240px;
    }
`

const CloseBtn = styled.button`
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border:none;
    font-size: 20px;
`

const Modal = ({ title, children, show, onHide }: Props) => {
    const content = useRef<HTMLElement>(null)

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const el = e.target as HTMLElement

            if (!content.current?.contains(el)) {
                onHide()
            }
        })
    }, [])

    useEffect(() => {
        if (show) {
            document.documentElement.style.overflow = 'hidden'
            return
        }

        document.documentElement.style.overflow = 'auto'
    }, [show])

    return (
        <Fragment>
            {show && (
                <ModalWrap>
                    <ModalContent ref={content}>
                        <CloseBtn onClick={onHide}>
                            X
                        </CloseBtn>
                        {title && <ModalTitle>{title}</ModalTitle>}
                        {children}
                    </ModalContent>
                </ModalWrap>
            )}
        </Fragment>
    )
}

export default Modal
