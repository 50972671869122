import styled from 'styled-components'

const Button = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #fff;
    border-radius: 12px;
    background: #cc5f42;
    font-weight: 700;
    border: none;
    font-family: Futura;
    font-size: 16px;
    min-width: 1px;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

export default Button